import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "./authentication.service.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    console.log(currentUser);
    console.log(route);
    if (currentUser != "undefined" && currentUser != null) {
      // authorised so return true
      // if (route.data.role && route.data.role != currentUser.role) {
      //   // role not authorised so redirect to home page
      //   this.authenticationService.logout();
      //   this.router.navigate(["/login"]);
      //   return false;
      // }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"]);
    return false;
  }
}
