import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, config, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "uzsNvlQrX4AxiGpkUm5WCp5Bt8bc3XdH",
    }),
  };
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    console.log(this.currentUser);
  }

  public get currentUserValue() {
    console.log(
      "this.currentUserSubject.value ",
      this.currentUserSubject.value
    );
    return this.currentUserSubject.value;
  }

  validateOTP(emailAddress, otp) {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/woop/broadcast/1.0.0/validateotp",
        { emailAddress, otp },
        this.httpOptions
      )
      .pipe(
        map((response) => {
          console.log("response ", response);
          response.isValid = true;
          response.adminDetails = {
            team: "WOOP",
            email: "ayyub@quickwork.co",
          };
          if (response.isValid) {
            localStorage.setItem(
              "currentUser",
              JSON.stringify(response.adminDetails)
            );
            console.log(response.adminDetails);
            this.currentUserSubject.next(response.adminDetails);
          }
          console.log("printed");
          return response;
        })
      );
  }
  generateOTP(emailAddress) {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/woop/broadcast/1.0.0/generateotp",
        { emailAddress },
        this.httpOptions
      )
      .pipe(
        map((response) => {
          // localStorage.setItem("currentUser", JSON.stringify(user));
          // this.currentUserSubject.next(user);
          // return user;
          return response;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }
}
