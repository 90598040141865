import { Component, OnInit } from "@angular/core";

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: "/dashboard", title: "Dashboard", icon: "nc-send", class: "" },
  {
    path: "/broadcastlist",
    title: "Reports",
    icon: "nc-bullet-list-67",
    class: "",
  },
  //{ path: "/printmrp", title: "Print MRP", icon: "nc-money-coins", class: "" },
];

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  ngOnInit() {
    if (this.isAdmin() == "admin") {
      this.menuItems = [
        {
          path: "/vendors",
          title: "Manage Vendors",
          icon: "nc-money-coins",
          class: "",
        },
      ];
    } else {
      this.menuItems = ROUTES.filter((menuItem) => menuItem);
    }
  }
  isAdmin() {
    if (
      JSON.parse(localStorage.getItem("currentUser")) != "undefined" &&
      JSON.parse(localStorage.getItem("currentUser")) != null
    ) {
      return JSON.parse(localStorage.getItem("currentUser"))["role"];
    }
  }
}
